"use strict";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later), measurementId is optional
Object.defineProperty(exports, "__esModule", { value: true });
const firebaseConfig = {
    apiKey: String(process.env.REACT_APP_FIREBASE_CONFIG_KEY),
    authDomain: String(process.env.REACT_APP_FIREBASE_AUTH_DOMAIN),
    projectId: String(process.env.REACT_APP_FIREBASE_PROJECT_ID),
    storageBucket: String(process.env.REACT_APP_FIREBASE_STORAGE_BUCKET),
    messagingSenderId: String(process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID),
    appId: String(process.env.REACT_APP_FIREBASE_APP_ID),
    measurementId: String(process.env.REACT_APP_FIREBASE_MEASUREMENT_ID),
};
exports.default = firebaseConfig;
