"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomePage = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = __importStar(require("react"));
const jotai_1 = require("jotai");
const atoms_1 = require("../../../../atoms");
const api_util_1 = require("../../../../utils/api.util");
require("./home.css");
const charts_1 = require("../../../../components/charts/charts");
const hooks_util_1 = require("../../../../utils/hooks.util");
const HomePage = () => {
    const [data, setData] = react_1.default.useState(null);
    const [user, setUser] = (0, jotai_1.useAtom)(atoms_1.authUserAtom);
    const [projects, setProjects] = (0, jotai_1.useAtom)(atoms_1.userProjectsAtom);
    const [systemChartData, setSystemChartData] = (0, jotai_1.useAtom)(atoms_1.systemChartsAtom);
    const [projectChartData, setProjectChartData] = react_1.default.useState([]);
    const getChartsData = () => {
        if (user.refreshToken)
            (0, api_util_1.getData)(user.refreshToken, `/projects/metrics/${user.id}?type=completed`, null, null, setUser).then((res) => {
                // Assuming `data` is the object containing your metrics
                const systemMetrics = res.data.systemMetrics;
                const projectMetrics = res.data.projectMetrics;
                const percentageCPU = systemMetrics.percentageCPU;
                const time = systemMetrics.time;
                // If there are more than 20 data points, remove the first one
                console.log(systemChartData.length);
                setSystemChartData([
                    ...systemChartData.length > 20 ? systemChartData.slice(1) : systemChartData,
                    {
                        value: percentageCPU, time: new Date(time).toLocaleTimeString()
                    }
                ]);
                console.log(systemChartData.length);
                setProjectChartData(projectMetrics.map((project, index) => {
                    var _a;
                    return ({
                        //  projects[project.projectId].name,
                        // Given a project ID, find the project name from the projects list
                        name: (_a = projects.find((p) => p.id === project.projectId)) === null || _a === void 0 ? void 0 : _a.name,
                        completed: project.completed.meta.count,
                        failed: project.failed.meta.count
                    });
                }));
            });
    };
    (0, hooks_util_1.useInterval)(() => {
        getChartsData();
    }, user.pollingEnabled ? user.pollingFrequency : null);
    (0, react_1.useEffect)(() => {
        getChartsData();
    }, [user.refreshToken]);
    return ((0, jsx_runtime_1.jsx)("div", { className: "page", children: (0, jsx_runtime_1.jsxs)("div", { className: "home-page", children: [(0, jsx_runtime_1.jsx)("span", { children: "System Metrics" }), (0, jsx_runtime_1.jsx)(charts_1.Chart, { data: systemChartData, type: "LINE_CHART" }), (0, jsx_runtime_1.jsx)("span", { children: "Project Metrics (2 weeks)" }), (0, jsx_runtime_1.jsx)(charts_1.Chart, { data: projectChartData, type: "BAR_CHART" })] }) }));
};
exports.HomePage = HomePage;
