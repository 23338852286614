"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectJoinForm = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = __importStar(require("react"));
const api_util_1 = require("../../utils/api.util");
const jotai_1 = require("jotai");
const atoms_1 = require("../../atoms");
const general_util_1 = require("../../utils/general.util");
const iconButton_1 = require("../iconButton/iconButton");
const icons_material_1 = require("@mui/icons-material");
const customIconButtonStyle = {
    backgroundColor: "rgb(3, 27, 78)",
    border: "solid 1px white",
    textDecoration: "none",
    boxShadow: "none",
    borderRadius: "4px",
    minWidth: "200px",
    color: "white",
    fontWeight: "bold",
};
const cancelIconButtonStyle = Object.assign(Object.assign({}, customIconButtonStyle), { backgroundColor: "white", color: "rgb(3, 27, 78)" });
const ProjectJoinForm = ({ projectId, closeDropdownAndModal, notificationId, notifications, setNotifications }) => {
    const [user, setUser] = (0, jotai_1.useAtom)(atoms_1.authUserAtom);
    const [admin, setAdmin] = (0, react_1.useState)(false);
    const [project, setProject] = (0, react_1.useState)({});
    (0, react_1.useEffect)(() => {
        // fetch project data
        (0, api_util_1.getData)(user.refreshToken, `/project/${projectId}`, setProject, null, setUser);
    }, []);
    // TODO: Might be worth having the requestorId also inside the props from notification body in websocket since it allows to determine who requested to join the project
    const joinProject = () => {
        (0, api_util_1.putData)(user.refreshToken, `/project_user/join`, { projectId: project.id, currentUserId: user.id }, "Joined Project", setUser);
        // Nav to that page
        window.location.href = `/project/${project.id}`;
        (0, api_util_1.putData)(user.refreshToken, `/user/notifications/${notificationId}`, { read: true }, null, setUser);
        setNotifications(notifications.filter((notification) => notification.id !== notificationId));
        closeDropdownAndModal();
        //TODO: Update the notification to read
    };
    return ((0, jsx_runtime_1.jsxs)("div", { className: "project-join", children: [(0, jsx_runtime_1.jsx)("strong", { children: "Project: " }), (0, jsx_runtime_1.jsx)("span", { children: project.name }), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("strong", { children: "Project Description: " }), (0, jsx_runtime_1.jsx)("span", { children: project.description }), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("strong", { children: "Project Creation date: " }), (0, jsx_runtime_1.jsx)("span", { children: (0, general_util_1.convertTimestampToDate)(String(project.createdAt)) }), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsxs)("div", { className: "project-join-buttons-container", style: { display: "flex", justifyContent: "space-between", marginTop: "10px" }, children: [(0, jsx_runtime_1.jsx)(iconButton_1.IconButton, { customIconButtonStyle: customIconButtonStyle, onClick: joinProject, content: "Join Project", icon: (0, jsx_runtime_1.jsx)(icons_material_1.CheckCircleOutline, {}) }), (0, jsx_runtime_1.jsx)(iconButton_1.IconButton, { customIconButtonStyle: cancelIconButtonStyle, content: "Cancel", icon: null, onClick: () => closeDropdownAndModal() })] })] }));
};
exports.ProjectJoinForm = ProjectJoinForm;
