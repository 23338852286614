"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initializeSocket = void 0;
const initializeSocket = (user, setNotifications, socket) => {
    console.log(">>> Initializing socket");
    socket.addEventListener("open", (event) => {
        socket.send("Hello Server!");
    });
    socket.addEventListener("message", (event) => {
        const data = JSON.parse(event.data);
        // Data can be a notification for User approval, job completion, project request
        // TODO: Fix this, every user shouln't get every notification!!!
        // Job completion notifications are sent to the user who created the job
        // Approval requests are sent to the admin
        // If the receiver is not the user, then return
        if (data.userId != user.id)
            return;
        // If the sender is the user, then return
        if (data.requestUserId && data.requestUserId == user.id)
            return;
        setNotifications((prev) => [...prev, data]);
    });
    // Restart socket if client socket closes unexpectedly
    // socket.onclose = () => {
    //     console.log("Socket closed unexpectedly")
    //     connection.current = new WebSocket(String(process.env.REACT_APP_WS_URL));
    // }
};
exports.initializeSocket = initializeSocket;
