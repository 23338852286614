"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const React = __importStar(require("react"));
const FormControl_1 = require("@mui/base/FormControl");
const Input_1 = require("@mui/base/Input");
const system_1 = require("@mui/system");
function BasicFormControl({ value, required, setUpdatedValue, width }) {
    const onChange = (e) => {
        setUpdatedValue && setUpdatedValue(e.target.value);
    };
    return ((0, jsx_runtime_1.jsxs)(FormControl_1.FormControl, { value: value, required: required, onChange: onChange, children: [(0, jsx_runtime_1.jsx)(StyledInput, { width: width ? width : "220px" }), (0, jsx_runtime_1.jsx)(HelperText, {})] }));
}
exports.default = BasicFormControl;
const StyledInput = (0, system_1.styled)(Input_1.Input)(({ theme, width }) => `

  .${Input_1.inputClasses.input} {
    width: ${width};
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 4px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

    &:hover {
      border-color: rgb(3, 27, 78);
    }

    &:focus {
    //   box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? "rgb(3, 27, 78)" : "rgb(3, 27, 78)"};
   border: 2px dotted rgb(3, 27, 78);
    outline: 1px solid rgb(3, 27, 78)

    }
  }
`);
const HelperText = (0, system_1.styled)((props) => {
    const formControlContext = (0, FormControl_1.useFormControlContext)();
    const [dirty, setDirty] = React.useState(false);
    React.useEffect(() => {
        if (formControlContext === null || formControlContext === void 0 ? void 0 : formControlContext.filled) {
            setDirty(true);
        }
    }, [formControlContext]);
    if (formControlContext === undefined) {
        return null;
    }
    const { required, filled } = formControlContext;
    const showRequiredError = dirty && required && !filled;
    return showRequiredError ? (0, jsx_runtime_1.jsx)("p", Object.assign({}, props, { children: "This field is required." })) : null;
}) `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
`;
const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};
