"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.skillTreesAtom = exports.userJobsAtom = exports.systemChartsAtom = exports.isLoadingAtom = exports.userProjectsAtom = exports.authUserAtom = exports.authTokenAtom = void 0;
const utils_1 = require("jotai/utils");
// TODO: Sometimes this doesnt work, localStorage might be better
exports.authTokenAtom = (0, utils_1.atomWithStorage)("authToken", ((_a = localStorage.getItem("authToken")) === null || _a === void 0 ? void 0 : _a.replace(/(^"|"$)/g, "")) || "");
exports.authUserAtom = (0, utils_1.atomWithStorage)("authUser", JSON.parse(localStorage.getItem("authUser") || "{}"));
exports.userProjectsAtom = (0, utils_1.atomWithStorage)("userProjects", JSON.parse(localStorage.getItem("userProjects") || "{}"));
exports.isLoadingAtom = (0, utils_1.atomWithStorage)("isLoading", false);
exports.systemChartsAtom = (0, utils_1.atomWithStorage)("systemCharts", JSON.parse(localStorage.getItem("systemCharts") || "[]"));
exports.userJobsAtom = (0, utils_1.atomWithStorage)("userJobs", JSON.parse(localStorage.getItem("userJobs") || "{}"));
// SkillTrees
exports.skillTreesAtom = (0, utils_1.atomWithStorage)("skillTrees", JSON.parse(localStorage.getItem("skillTrees") || "[]"));
