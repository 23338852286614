"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Chart = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_use_1 = require("react-use");
const recharts_1 = require("recharts");
const Chart = ({ data, type }) => {
    const [chartRef, chartDimensions] = (0, react_use_1.useMeasure)();
    return ((0, jsx_runtime_1.jsx)("div", { ref: chartRef, style: { width: "100%" }, children: type === "LINE_CHART" ?
            (0, jsx_runtime_1.jsxs)(recharts_1.LineChart, { width: chartDimensions.width, height: 300, data: data, children: [(0, jsx_runtime_1.jsx)(recharts_1.CartesianGrid, { stroke: "#ccc" }), (0, jsx_runtime_1.jsx)(recharts_1.XAxis, { dataKey: "time" }), (0, jsx_runtime_1.jsx)(recharts_1.YAxis, {}), (0, jsx_runtime_1.jsx)(recharts_1.Tooltip, {}), (0, jsx_runtime_1.jsx)(recharts_1.Legend, {}), (0, jsx_runtime_1.jsx)(recharts_1.Line, { type: "monotone", dataKey: "value", stroke: "rgb(3, 27, 78)", name: "CPU Percent" })] })
            :
                (0, jsx_runtime_1.jsxs)(recharts_1.BarChart, { width: chartDimensions.width, height: 300, data: data, children: [(0, jsx_runtime_1.jsx)(recharts_1.CartesianGrid, { stroke: "#ccc" }), (0, jsx_runtime_1.jsx)(recharts_1.XAxis, { dataKey: "name" }), (0, jsx_runtime_1.jsx)(recharts_1.YAxis, {}), (0, jsx_runtime_1.jsx)(recharts_1.Tooltip, {}), (0, jsx_runtime_1.jsx)(recharts_1.Legend, {}), (0, jsx_runtime_1.jsx)(recharts_1.Bar, { dataKey: "completed", fill: "green", name: 'Jobs Completed' }), (0, jsx_runtime_1.jsx)(recharts_1.Bar, { dataKey: "failed", fill: "rgb(202, 12, 12)", name: 'Jobs Failed' })] }) }));
};
exports.Chart = Chart;
