"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWindowSize = exports.useInterval = void 0;
const react_1 = require("react");
/**
 * Credits: https://blog.bitsrc.io/polling-in-react-using-the-useinterval-custom-hook-e2bcefda4197
 */
function useInterval(callback, delay) {
    const savedCallback = (0, react_1.useRef)(() => { });
    // Remember the latest callback.
    (0, react_1.useEffect)(() => {
        savedCallback.current = callback;
    }, [callback]);
    // Set up the interval.
    (0, react_1.useEffect)(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => {
                clearInterval(id);
            };
        }
    }, [delay]);
}
exports.useInterval = useInterval;
/**
 *
 * https://stackoverflow.com/questions/19014250/rerender-view-on-browser-resize-with-react?answertab=modifieddesc#tab-top
 */
function useWindowSize() {
    const [size, setSize] = (0, react_1.useState)([0, 0]);
    (0, react_1.useLayoutEffect)(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}
exports.useWindowSize = useWindowSize;
